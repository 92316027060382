<template>
  <div class="page">
    <div class="top_info_box">
      <div class="top">
        <p>订单号：{{info.order_no}}</p>
        <p>订单状态：<span>{{ info.pay_status == 2 ? '待支付' : info.pay_status == 3 ? '已取消' : info.pay_status == 1 ? info.sign_status == 1 ? '已签到' : '待签到' : info.pay_status == 4 ? '退款中' : info.pay_status == 5 ? '已退款' :''}}</span></p>
      </div>
      <div class="content">
        <div class="left">
          <img :src="$global.regexImg(info.meet.logo_web)"
               alt="">
        </div>
        <div class="right">
          <p>{{ info.meet.meet_name }}</p>
          <p>{{info.meet.start_date}} - {{ info.meet.end_date}}</p>
          <p>{{ info.meet.address }}</p>
        </div>
      </div>
    </div>
    <div class="user_info_box">
      <div class="top">
        报名人员信息
      </div>
      <div class="content_row">
        <div class="row">
          <p><span>参会人：</span><span>{{info.user_info.own_info.find(c=>c.key == 'name') ? info.user_info.own_info.find(c=>c.key == 'name').value :''}}</span></p>
          <p><span>联系电话：</span><span>{{info.user_info.own_info.find(c=>c.key == 'phone') ? info.user_info.own_info.find(c=>c.key == 'phone').value :''}}</span></p>
        </div>
        <template v-if="info.user_info.other_info &&  info.user_info.other_info.length > 0">
          <div class="row"
               v-for="(row, index) in info.user_info.other_info"
               :key="index">
            <p><span>参会人：</span><span>{{row.find(c=>c.key == 'name') ? row.find(c=>c.key == 'name').value :''}}</span></p>
            <p><span>联系电话：</span><span>{{row.find(c=>c.key == 'phone') ? row.find(c=>c.key == 'phone').value :''}}</span></p>
          </div>
        </template>
      </div>
    </div>
    <div class="info_box">
      <div class="top">
        票种信息
      </div>
      <div class="content_row">
        <p><span>票种：</span><span>{{ info.ticket.name}}</span></p>
        <p><span>数量：</span><span>x{{ info.user_info.other_info && info.user_info.other_info.length > 0 ? info.user_info.other_info.length + 1 : 1 }}</span></p>
        <p><span>特殊优惠：</span><span>-{{ info.whitelist }}</span></p>
        <p><span>优惠（早鸟票）：</span><span>-{{ info.morning_bird }}</span></p>
        <p><span>实付金额：</span><span>{{ info.amount }}</span></p>
      </div>
    </div>
    <div class="info_box">
      <div class="top">
        订单信息
      </div>
      <div class="content_row">
        <p><span>订单号：</span><span>{{ info.order_no }}</span></p>
        <p><span>创建时间：</span><span>{{ info.regist_time}}</span></p>
        <p><span>支付方式：</span><span>{{payList[info.pay_type-1]}}</span></p>
      </div>
    </div>
    <div class="info_box"
         v-if='info.is_invoice == 1 && info.invoice && Object.keys( info.invoice).length> 0'>
      <div class="top">
        发票信息
      </div>
      <div class="content_row">
        <p><span>发票类型：</span><span>{{ info.invoice.type_text }}</span></p>
        <p><span>领取方式：</span><span>{{ info.invoice.receive_text}}</span></p>
        <p v-if='info.invoice.enterpriseCode'><span>企业名称：</span><span>{{ info.invoice.enterpriseName}}</span></p>
        <p v-if='info.invoice.enterpriseCode'><span>企业税号：</span><span>{{ info.invoice.enterpriseCode}}</span></p>
        <p v-if='info.invoice.registerAddress'><span>注册地址：</span><span>{{ info.invoice.registerAddress}}</span></p>
        <p v-if='info.invoice.bankDeposit'><span>开户银行：</span><span>{{ info.invoice.bankDeposit}}</span></p>
        <p v-if='info.invoice.bankAccount'><span>银行账户：</span><span>{{ info.invoice.bankAccount}}</span></p>
        <p v-if='info.invoice.address'><span>发送邮箱：</span><span>{{ info.invoice.email}}</span></p>
        <p v-if='info.invoice.address'><span>邮箱地址：</span><span>{{info.invoice.address}}</span></p>
        <p v-if='info.invoice.phone'><span>联系方式：</span><span>{{ info.invoice.phone}}</span></p>
      </div>
    </div>
    <div class="money_box">
      <p><span>应付金额：</span><span>¥{{info.amount}}</span></p>
      <div class="button_box"
           v-if="(info.pay_status == 1 || info.pay_status == 2)">
        <el-button v-if='info.pay_status == 1'
                   :loading='loading  && operationStatus == 3'
                   @click="operationStatus = 4;onETicket(info.id,info)"
                   class="btn1">电子票</el-button>
        <el-button class="btn1"
                   :loading='loading  && operationStatus == 4'
                   v-if='info.pay_status == 1'
                   @click="operationStatus = 4;onMealTicket(info.meet.id)">餐票</el-button>
        <el-button class="btn1"
                   v-if=" info.pay_status == 2"
                   @click="onPay(info.id)">去支付</el-button>
        <el-button v-if=" info.pay_status == 2  /* || (info.pay_status == 1 && info.pay_type == 5)*/ "
                   :loading='loading  && operationStatus == 1'
                   @click="operationStatus = 1;onCancel(info.id)">取消订单</el-button>
        <el-button v-if="info.pay_status == 1 && info.sign_status == 2 && (info.pay_type == 1 || info.pay_type == 2 )"
                   :loading='loading  && operationStatus == 2'
                   @click="operationStatus = 2; onRefund(info.id)">退款</el-button>
      </div>
    </div>
    <!-- 参票 -->
    <!--   :close-on-click-modal='false' -->
    <el-dialog :visible.sync="mealTicket.visible"
               :show-close='false'
               center
               width="500px">
      <div class="mealTicket_box">
        <div class="tab_box">
          <span v-for="(im,ix) in mealTicket.tabList"
                :key="ix"
                :class="mealTicket.tabActive == ix ? 'active' : ''"
                @click="changeMealTicket(ix)">{{im}}</span>
        </div>
        <div class="content">
          <div v-if='mealTicket.AllList[mealTicket.tabActive].total >0'>
            <div class="row"
                 :class="mealTicket.tabActive  == 1 ? 'tab2' :''"
                 v-for='(item,index) in mealTicket.AllList[mealTicket.tabActive].data'
                 :key='index'>
              <div class="left">
                <p>{{item.meal_ticket.name}}</p>
                <p> {{item.type == 1 ?'早餐' :item.type== 2 ?'午餐' :'晚餐'}}</p>
                <p><span>{{item.meal_ticket.eat_type}}</span><span>用餐{{item.num}}人</span></p>
                <p><span>{{item.meal_ticket.start_time}} - {{item.meal_ticket.end_time}}</span></p>
                <p>地点：{{item.meal_ticket.address}}</p>
              </div>
              <div class="right">
                <img :src="$global.regexImg(item.qrcode)"
                     mode="aspectFit"
                     class="code"
                     v-if='mealTicket.tabActive == 0'>
                <img src="@/assets/img/meeting/order_08.png"
                     mode="aspectFit"
                     v-if='mealTicket.tabActive == 1 && item.type == 2'>
                <img src="@/assets/img/meeting/order_07.png"
                     mode="aspectFit"
                     v-if='mealTicket.tabActive == 1 && item.type == 3'>
              </div>
            </div>
            <div class="pop_close">
              <i></i>
              <img src="@/assets/img/meeting/order_20.png"
                   @click='mealTicket.visible = false'
                   alt="">
            </div>
          </div>
          <tempData v-else />
        </div>
      </div>
    </el-dialog>
    <!-- 电子票 -->
    <!--   :close-on-click-modal='false' -->
    <el-dialog :visible.sync="electronicTicket.visible"
               :show-close='false'
               center
               width="480px">
      <div class="electronicTicket_box">
        <div class="content_box">
          <img :src="$global.regexImg(electronicTicket.obj.qrcode)"
               alt="">
          <p v-if='userInfo &&userInfo.real_name'
             class="p1">{{userInfo.real_name}}</p>
          <p v-if='userInfo && userInfo.unit'
             class="p2">{{userInfo.unit}}</p>
        </div>
        <div class="pop_close">
          <i></i>
          <img src="@/assets/img/meeting/order_20.png"
               @click='electronicTicket.visible = false'
               alt="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_schedule_list, set_cancelJoin, set_cancelRoom, get_mealTicket, set_refund, set_ticketPay } from '@/utils/Api/meeting'
import tempData from '@/components/tempData'
export default {
  components: { tempData },
  props: [],
  data () {
    return {
      payList: ['支付宝', '微信', '现场支付', '线下转账', '免费'],
      operationStatus: null,
      mealTicket: {
        visible: false,
        id: null,
        AllList: [
          { data: [], page: 1, total: 0, size: 5, first: false },
          { data: [], page: 1, total: 0, size: 5, first: false },
        ],
        tabActive: 0,
        tabList: ['待使用', '已失效'],
      },
      electronicTicket: {
        visible: false,
        id: null,
        obj: {
        },
      },
      loading: false,
      orderId: null,
      info: {
        meet: {},
        ticket: {},
        user_info: {
          other_info: [],
          own_info: []
        },
        invoice: {}

      }
    }
  },
  methods: {
    async getInfo () {
      var res = await get_schedule_list({ page: 1, limit: 1, type: 2, meet_id: this.orderId })
      if (res) {
        this.info = res.data[0]
      }
    },
    async onPay (id) {
      if (Number(this.info.amount) <= 0) {
        let params = {
          join_meet_id: id,
          amount: this.info.amount,
          pay_type: 5,
          duan_type: 2,
          ticket_id: this.info.meet.id,
        }
        let res = await set_ticketPay(params)
        if (res) {
          this.$message.success('免单成功')
          this.getInfo()
        }
      } else {
        this.$router.push({ path: '/onlinePayment', query: { type: 1, orderId: id } })
      }
    },
    onETicket (id, row) {
      if (this.loading) return
      this.electronicTicket.id = id
      this.electronicTicket.obj = row
      this.electronicTicket.visible = true
    },
    onMealTicket (id, i) {
      if (this.loading) return
      this.mealTicket.id = id
      this.getMealTicketList()
    },
    changeMealTicket (ix) {
      this.mealTicket.tabActive = ix
      if (this.mealTicket.AllList[ix].first) return
      this.getMealTicketList()

    },
    onCancel (id, i) {
      if (this.loading) return
      this.$confirm('您确认取消订单吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        var res = await set_cancelJoin({ order_id: id })
        if (res) {
          this.$message.success('订单已取消')
          this.getInfo()
        } else {
          this.$message.warning('操作失败')
        }
      }).catch(() => { })
    },
    onRefund (id, i) {
      this.$confirm('您确认申请退款吗？', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async () => {
        this.loading = true
        var res = await set_refund({ id: id })
        if (res) {
          this.$message.success('退款申请已提交')
          this.getInfo()
        } else {
          this.$message.warning('操作失败')
        }
      }).catch(() => { })
    },
    async getMealTicketList () {
      this.loading = true
      var res = await get_mealTicket({ limit: 1000, page: 1, status: this.mealTicket.tabActive + 1, meet_id: this.mealTicket.id })
      this.loading = false
      if (res) {
        this.mealTicket.AllList[this.mealTicket.tabActive].first = true
        this.mealTicket.AllList[this.mealTicket.tabActive].total = res.total
        this.mealTicket.AllList[this.mealTicket.tabActive].data = res.data
        this.mealTicket.visible = true
      }
    },
  },
  created () {
    this.orderId = this.$route.query.orderId
    this.getInfo()
  },
  mounted () { },
  destroyed () { },
  computed: {
    userInfo () {
      return this.$store.getters.personal
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.page {
  padding-left: 22px;
  box-sizing: border-box;
}
.top_info_box {
  margin-bottom: 17px;
  width: 100%;
  font-size: 13px;
  color: #333;
  .content {
    border-left: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 30px 18px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .left {
      text-align: center;
      height: 108px;
      width: 187px;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 108px;
        max-width: 187px;
      }
    }
    .right {
      text-align: left;
      width: calc(100% - 205px);
      > p:nth-child(1) {
        width: 60%;
        height: 50px;
        margin-bottom: 4px;
        color: #333;
        font-size: 16px;
        font-weight: 700;
        display: block;
        overflow: hidden;
        text-overflow: ellispsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      > p:nth-child(2) {
        color: #8e8e8e;
        font-size: 12px;
        margin-bottom: 16px;
      }
      > p:nth-child(3) {
        color: #333;
        font-size: 13px;
      }
    }
  }
  .top {
    text-align: left;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 41px;
    line-height: 41px;
    background: #f7fbff;
    > p {
      display: inline-block;
      &:last-child {
        margin-left: 70px;
        span {
          color: #ff0036;
        }
      }
    }
  }
}
.user_info_box,
.info_box {
  margin-bottom: 17px;
  .top {
    text-align: left;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    height: 41px;
    line-height: 41px;
    background: #f7fbff;
  }
  .content_row {
    border-left: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 30px 18px 15px;
    box-sizing: border-box;
    text-align: left;
    p {
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      margin-bottom: 12px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      span:nth-child(1) {
        width: 120px;
        font-size: 13px;
        line-height: 18px;
        color: #333;
      }
      span:nth-child(2) {
        width: calc(100% - 120px);
        line-height: 18px;
        color: #666;
      }
    }
  }
}
.money_box {
  margin: 70px 0 100px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: right;
  > p {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 20px;
    span:nth-child(1) {
      margin-right: 20px;
    }
    span:nth-child(2) {
      color: #f03708;
    }
  }
  > div {
    display: flex;
    flex-direction: row-reverse;
    .el-button {
      align-self: center;
      width: 73px;
      height: 28px;
      font-size: 13px;
      line-height: 28px;
      margin-bottom: 5px;
      border: 1px solid #47d7e3;
      color: #47d7e3;
      padding: 0;
      margin-left: 8px;
      border-radius: 2px;
    }
    .btn1 {
      background: #47d7e3;
      border-radius: 2px;
      color: #fff;
    }
  }
}
// 弹窗
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog {
  background-color: transparent;
}
.pop_close {
  position: absolute;
  bottom: -110px;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  i {
    display: inline-block;
    background-color: #fff;
    height: 60px;
    width: 6px;
  }
  img {
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
}
.electronicTicket_box {
  position: relative;
  box-sizing: border-box;
  .content_box {
    border-radius: 20px;
    overflow: hidden;
    // height: 500px;
    width: 100%;
    box-sizing: border-box;
    padding: 60px 30px 110px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 250px;
      height: 250px;
      margin-bottom: 30px;
    }
    .p1 {
      text-align: center;
      font-size: 25px;
      color: #333;
      font-weight: 700;
    }
    .p2 {
      margin-top: 20px;
      font-size: 18px;
      color: #333;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.mealTicket_box {
  background-color: #fff;
  height: 570px;
  border-radius: 20px;
  overflow: hidden;
  .tab_box {
    height: 78px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid #ebebeb;
    cursor: pointer;
    > span {
      box-sizing: border-box;
      text-align: center;
      position: relative;
      flex: 1;
      height: 100%;
      line-height: 77px;
      font-size: 16px;
      color: #333;
      font-weight: 700;
      &:last-child {
        border-left: 1px solid #ebebeb;
      }
    }
    .active {
      color: #47d7e3;
      &::before {
        position: absolute;
        content: '';
        bottom: 0;
        z-index: 4;
        left: 50%;
        transform: translateX(-50%);
        width: 47px;
        height: 4px;
        background: #47d7e3;
      }
    }
  }
  .content {
    height: 460px;
    overflow-y: auto;
    .tab2 .left > p {
      color: #b7b7b7 !important;
    }
    .row {
      position: relative;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      .left {
        width: calc(100% - 120px);
        > p:nth-child(1) {
          color: #777777;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 6px;
        }
        > p:nth-child(2) {
          color: #333333;
          font-size: 26px;
          line-height: 37px;
          font-weight: 700;
          margin-bottom: 12px;
          span:last-child {
            margin-left: 20px;
            color: #333333;
            font-size: 12px;
            font-weight: normal;
          }
        }
        > p:nth-child(3) {
          color: #333333;
          font-size: 14px;
          line-height: 22px;
          span {
            display: inline-block;
            margin-right: 14px;
            margin-bottom: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        > p:nth-child(4) {
          font-weight: 700;
          color: #343434;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 4px;
        }
        > p:nth-child(5) {
          font-weight: 700;
          color: #343434;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .right {
        text-align: right;
        padding-right: 15px;
        img {
          margin-top: 10px;
          width: 113px;
          height: 85px;
        }
        .code {
          width: 69px;
          height: 69px;
        }
      }
    }
  }
}
</style>
